:root {
  --popup-bankadr-bg: white;
  --popup-bankadr-title-color: #404040;
  --popup-bankadr-cancel-color: #c58e4b;
}

[data-theme='night'] {
  --popup-bankadr-bg: #26292e;
  --popup-bankadr-title-color: white;
  --popup-bankadr-cancel-color: #fddba2;
}

.header {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: var(--popup-bankadr-cancel-color);
  padding: 0px 15px;
  border-bottom: 1px solid #f0f0f0;
}

[data-theme='night'] {
  .header {
    border-bottom: 1px solid transparent;
  }
}

.content {
  background-color: var(--popup-bankadr-bg);
  height: 216px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.body {
  :global(.adm-picker-view-column-wheel) {
    top: 56px;
  }
  :global(.adm-picker-view-column-item) {
    font-size: 16px;
    color: var(--popup-bankadr-title-color);
  }
  :global(.adm-picker-view-column-item-active) {
    color: var(--popup-bankadr-title-color);
  }
  :global(.adm-picker-view-mask-top) {
    flex: none;
    height: 51px;
  }
}

.scroll {
  height: 100%;
  overflow-y: scroll;
}

.loading {
  background-color: transparent !important;
}
