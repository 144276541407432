

.submit_button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 15px;
  height: 44px;
}

.submit_button button {
  background-color: var(--secondary-color-9);
  width: 100%;
  height: 100%;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  color: var(--secondary-color-2);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
}

.submit_button button:disabled {
  opacity: 0.5;
}

.middle {
  width: 150px;
  margin: auto;
}