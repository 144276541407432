.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.send_code {
  padding: 6px 19px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gradient-color-1);
  border-radius: 32px;
  font-size: 12px;
  text-wrap: nowrap;
  gap: 3px;
  color: var(--secondary-color-2);
  white-space: nowrap; /* 确保文本不换行 */
}

.disabled {
  opacity: 0.6;
}

.loader {
  width: 16px;
  height: 16px;
  border: 2px solid var(--secondary-color-6);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  animation: pulse 1s linear infinite;
  opacity: 0.7;
  margin-bottom: 1px;
}

.loader:after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border: 2px solid var(--secondary-color-6);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: scaleUp 1s linear infinite;
}

@keyframes scaleUp {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  60%,
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes pulse {
  0%,
  60%,
  100% {
    transform: scale(0.8);
  }
  80% {
    transform: scale(1.0);
  }
}