.form_list {
  padding: 0 15px;

  :global(.adm-list-item) {
    padding: 0;
  }

  :global(.adm-list-item-content) {
    padding: 0;
  }

  :global(.adm-list-item-content-main) {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  :global(.adm-list-item-title) {
    font-size: 16px;
    font-weight: 500;
    color: var(--color-level-1);
  }

  :global(.adm-list-item-content-main input) {
    border: 0;
    background-color: transparent;
    font-size: 16px;
    width: 100%;
    color: var(--color-level-1);
  }

  :global(.adm-list-item-content-main input::placeholder) {
    color: var(--color-level-3);
  }

  :global(.adm-list-item) {
    border-bottom: 0.5px solid var(--secondary-color-5);
  }
}
