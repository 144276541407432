#root {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;

  background: var(--background-color-1);
  color: var(--color-level-1);
  user-select: none;
  font-family:
    PingFang SC,
    PingFangSC-Regular,
    DinPro,
    dinpro,
    SF Pro SC,
    SF Pro Text,
    Microsoft Yahei,
    Helvetica Neue,
    Helvetica,
    Arial,
    sans-serif;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.App {
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
}

#webpack-dev-server-client-overlay {
  display: none;
}

::-webkit-scrollbar {
  width: 0;
}

/* 可选：清除边框变色 */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition-delay: 5000s;
  transition:
    color 5000s ease-out,
    background-color 5000s ease-out;

  -webkit-transition-delay: 5000s;
  -webkit-transition:
    color 5000s ease-out,
    background-color 5000s ease-out;

  box-shadow: 0 0 0 1000px #ffffff00 inset !important;
  color: #404040 !important; /* 修改文字颜色，您可以根据需要调整颜色值 */
  -webkit-box-shadow: 0 0 0 1000px #ffffff00 inset !important;
  -webkit-text-fill-color: var(--secondary-color-2) !important; /* 修改文字颜色，您可以根据需要调整颜色值 */

  border: 0px solid #ffffff00 !important;
  border-left-width: 0px !important;
  border-right-width: 0px !important;
}

[data-theme='night'] {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition-delay: 5000s;
    transition:
      color 5000s ease-out,
      background-color 5000s ease-out;

    -webkit-transition-delay: 5000s;
    -webkit-transition:
      color 5000s ease-out,
      background-color 5000s ease-out;

    box-shadow: 0 0 0 1000px #26292e inset !important;
    color: #ffffff00 !important; /* 修改文字颜色，您可以根据需要调整颜色值 */
    -webkit-box-shadow: 0 0 0 1000px #26292e00 inset !important;
    -webkit-text-fill-color: #ffffff00 !important; /* 修改文字颜色，您可以根据需要调整颜色值 */

    border: 0px solid #26292e !important;
    border-left-width: 0px !important;
    border-right-width: 0px !important;
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox 下隐藏箭头按钮 */
input[type='number'] {
    -moz-appearance: textfield;
}