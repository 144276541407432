.content {
  display: flex;
  align-items: center;
  gap: 12px;
}

.item {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 17px;
  border-radius: 8px;
  background-color: var(--secondary-color-2);
  font-size: 14px;
  position: relative;
}
.active {
  border: 1px solid var(--primary-color-1);
  color: var(--primary-color-1);
}

.item div:last-child {
  position: absolute;
  right: 0;
  bottom: 0;
}