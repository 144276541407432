.down_load {
  position: sticky;
  top: 0;
  left: 0;
  background-color: var(--secondary-color-10);
  z-index: 1;
}
.header {
  height: (44px);
  display: flex;
  background-color: var(--secondary-color-10);
  align-items: center;
  gap: (6px);
  position: relative;
}

.icon {
  width: (20px);
  height: (20px);
  margin-left: (6px);
}

.image {
  width: (36px);
  height: (36px);
  border-radius: (8px);
  margin-right: (4px);
}

.desc {
  color: var(--secondary-color-2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 600;
}

.desc h1 {
  font-size: (14px);
}

.desc span {
  font-size: (10px);
  white-space: nowrap; /* 确保文本不换行 */
  font-weight: 500;
}

.download {
  margin-left: auto;
  margin-right: (10px);
}

.download button {
  width: (66px);
  height: (24px);
  border-radius: (4px);
  background-color: var(--secondary-color-9);
  color: var(--secondary-color-2);
  font-size: (12px);
  white-space: nowrap;
}
