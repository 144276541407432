

.dialog {
  :global(.adm-dialog-body) {
    overflow: unset !important;
    padding: 0 !important;
    height: (214px);
  }
  :global(.adm-dialog-content) {
    overflow: visible;
    padding: 0px !important;
  }
  :global(.adm-center-popup-wrap) {
    max-width: (282px) !important;
    min-width: (282px) !important;
  }
}
.dialog_content {
  position: relative;
  height: 100%;
}

.img {
  position: absolute;
  height: (143px);
  top: (-40px);
  right: 0;
}

.content {
  position: absolute;
  bottom: 0;
  padding: 0 15px;
  padding-bottom: 20px;
  width: 100%;
}

.content_div {
  display: flex;
  align-items: center;
  background-color: var(--secondary-color-1);
  padding: 20px 8px 20px 8px;
  border-radius: 12px;
  gap: 6px;
  white-space: nowrap;
  width: 100%;
}

.content_div img {
  width: 40px;
  height: 40px;
 
}

.content_div > div:nth-child(2) {
  display: flex;
  flex-direction: column;
}
.content_div > div:nth-child(2) > span:first-child {
  font-size: 12px;
  color: var(--color-level-1);
  font-weight: 600;
}
.content_div > div:nth-child(2) > span:last-child {
  font-size: 9px;
  color: var(--color-level-1);
}

.content_div > button {
  background: var(--gradient-color-2);
  color: var(--secondary-color-2);
  font-size: 12px;
  font-weight: 500;
  padding: 5px 16px;
  border-radius: 29px;
  margin-left: 16px;
}

.icon {
  width: 24px;
  height: 24px;
  right: -10px;
  position: absolute;
  color: #F2F2F5;
  top: -30px;
  z-index: 10;
}