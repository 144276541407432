.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clear {
  height: 19px;
  width: 50px;
  position: relative;
}

.icon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-level-2);
}

.area_code {
  text-wrap: nowrap;
  font-size: 16px;
  //color: var(--color-level-1);
  color: var(--color-level-3);
  display: flex;
  align-items: center;
  gap: 12px;
  margin-right: 12px;
  white-space: nowrap; /* 确保文本不换行 */
}

.area_code div:last-child{
  background-color: var(--color-level-3);
}
.activity span:first-child {
  color: var(--color-level-1);
}
.activity div:last-child{
  background-color: var(--color-level-1);
}

.v_line {
  width: 1px;
  height: 15px;
  background-color: var(--color-level-1);
}

// .activity .v_line {
//   background-color: var(--color-level-3);
// }
