@font-face {
  font-family: 'DinBold';
  src: url('../assets/fonts/din-bold-2-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

a,
abbr,
acronym,
address,
applet,
big,
blockquote,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
font,
form,
h1,
h2,
h3,
h4,
h5,
h6,
iframe,
img,
ins,
kbd,
label,
legend,
li,
object,
ol,
p,
pre,
q,
s,
samp,
small,
span,
strike,
strong,
sub,
sup,
tt,
ul,
var {
  margin: 0;
  padding: 0;
  outline: 0;
}

* {
  padding: 0;
  margin: 0;
  outline: 0 none;
  box-sizing: border-box;
  word-break: break-all;
  text-decoration: unset;
  user-select: none;
  cursor: unset !important;
}

input,
textarea {
  user-select: auto;
  caret-color: var(--primary-color-1);
}
ol,
ul {
  list-style: none;
  list-style-type: none;
}

/* 针对整个页面 */
html {
  overflow-y: scroll; /* 保持滚动功能 */
  scrollbar-width: none; /* Firefox */
}

html::-webkit-scrollbar {
  display: none; /* Chrome, Safari 和 Opera */
}


button {
    border: 0;
    font-size: inherit;
    font-weight: inherit;
}

*::-webkit-scrollbar {
  width: 0px;  /* 移动垂直滚动条 */
  height: 0px;  /* 移动水平滚动条 */
  display: none; /* 针对 Webkit 浏览器 */
}


// .adm-tabs-tab{
//   cursor: none !important;
// }