.banner {
  background-color: var(--secondary-color-10);
  padding-bottom: 56px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin-bottom: -62px;
  position: sticky;
}

.hide {
  top: -141px;
}
.un_hide {
  top: -97px;
}

.swiper {
  width: 100%;
  position: relative;
  z-index: 0;
  height: (141px);

  :global(.swiper-pagination-bullet) {
    width: (8px);
    height: (4px);
    border-radius: (2px);
    background: var(--color-level-3);
    opacity: 1;
  }
  :global(.swiper-pagination-bullet-active) {
    background: var(--secondary-color-2);
    opacity: 1;
    width: (12px);
  }
}


.swiper_content {
  width: 100%;
  height: 100%;
}

.swiper_content img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.item_banner_image {
  position: relative;
  height: 100%;
  background-color: var(--secondary-color-10);
}

.item_banner_image > img:last-child {
  position: absolute;
  top: 0;
}

.image_placeholer {
  position: relative;
}
