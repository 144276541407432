.error_content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 13px;
}

.content {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 3px;
  color: var(--secondary-color-8);
  font-size: 14px;
  opacity: 0;
}

.icon {
  width: 17px;
  height: 17px;
}


.show {
    opacity: 1;
}