.loading_ui {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: var(--background-color-1);
    height: 100%;
}

.full_sceen {
    min-height: 100vh;
}

.error {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    background-color: var(--background-color-1);
    width: 100%;
    height: 100%;
}

.error span {
    font-size: 14px;
    color: var(--secondary-color-9);
}

.error button {
    margin-bottom: 80px;
    background-color: var(--secondary-color-11);
    border: 0;
    width: 148px;
    height: 48px;
    border-radius: 42px;
    font-size: 18px;
    font-weight: 500;
    color: var(--secondary-color-6);
}