.content {
  display: flex;
  align-items: center;
  gap: 12px;
}

.item {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px;
  border-radius: 4px;
  background-color: var(--secondary-color-2);
}

.item img {
  width: 31px;
  height: 20px;
}

.active {
  border: 0.5px solid var(--primary-color-1);
  color: var(--primary-color-1);
}
