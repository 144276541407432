.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clear {
  height: 19px;
  width: 50px;
  position: relative;
}

.icon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-level-2);
}


.eye_icon {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  fill: var(--color-level-2);
}