.menu {
  padding: 0 15px;
  position: sticky;
  z-index: 1;
  padding-bottom: 7px;
}

.hide {
  top: 0;
}
.un_hide {
  top: 44px;
}

.menu_content {
  height: 108px;
  width: 100%;
  padding: 4px 12px 5px 12px;
}


.line {
  margin-top: 4px;
  width: 100%;
  height: 0.5px;
  background-color: var(--color-level-2);
}

.marquee {
  padding: 0 !important;
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.un_login div:first-child{
  font-size: 14px;
  color: var(--secondary-color-2);
  margin-top: 10px;
}

.un_login div:nth-child(2){
  font-size: 10px;
  color: var(--secondary-color-3);
  margin-top: 4px;
  margin-bottom: 4px;
}

.bottom_right {
  display: flex;
  align-items: center;
}

.menu_item {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  color: var(--secondary-color-2);
}
.menu_item img{
  width: 44px;
  height: 44px;
}
.login {
  margin-top: 4px;
}
.login div:first-child {
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 500;
  gap: 4px;
}
.login div:first-child img:first-child{
 width: 20px;
 height: 20px;
}
.login div:first-child img:last-child {
  width: 42px;
}

.login div:nth-child(2) {
  display: flex;
  align-items: baseline;
  margin-bottom: 3px;
}
.login div:nth-child(2) > span:first-child {
 font-size: 16px;
 font-weight: 500;
}
.login div:nth-child(2) > span:nth-child(2) {
  font-size: 24px;
  font-weight: 700;
 }

 .login div:nth-child(2) > img:last-child {
  height: 14px;
  width: auto;
  margin-left: 3px;
 }

 .ip {
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  color: var(--secondary-color-3);
  gap: 3px;
  white-space: nowrap;
 }


.popver {
  margin-top: 10px;
}



.dialog {
  :global(.adm-dialog-content) {
    padding: 20px 15px 15px 15px !important;
  }
}

.login {
  color: var(--secondary-color-2);
}

.dialog_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.dialog_content > span:first-child {
  font-size: 16px;
  font-weight: 600;
  color: var(--color-level-1);
}

.dialog_content > div:nth-child(2) {
  font-size: 12px;
  color: var(--color-level-2);
}

.dialog_content > button {
  background: var(--secondary-color-11);
  font-size: 10px;
  padding: 1px 16px;
  border-radius: 25px;
  color: var(--secondary-color-6);
  line-height: 22px;
}


.wallet_icon {
  margin-left: 3px;
}