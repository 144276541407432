.container {
  position: fixed;
  width: var(--safe-html-max-width);
  z-index: 99;
  height: (68px);
  bottom: -1px;
  left: 50%;
  right: 0;
  font-size: (10px);
  transform: translateX(-50%);
  padding-bottom: calc(70px);
  color: var(--color-level-3);
}

.bottom_bar {
  position: absolute;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
}

.tabbar_item {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-bottom: (-15px);
}


.tabbar_wrap {
    width: (20px);
    height: (20px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    position: relative;
}

.tabbar_item span {
    z-index: 1;
}

.tabbar_item:nth-child(3) span {
    margin-top: (10px);
}
.img {
  margin-bottom: 5px;
}

.active {
    color: var(--color-level-1);
}
