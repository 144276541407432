:root {
  --base-secondary-color-8: 242, 61, 61;
  --base-primary-color: 102, 141, 255;
  --base-secondary-color-6: 8, 216, 54;
  --base-secondary-color-1: 247, 247, 247;
  --base-secondary-color-12: 75, 75, 75;
  --base-color-level-3: 153, 153, 153;
  --base-secondary-color-2: 255, 255, 255;
  --base-color-level-1: 33, 37, 47;
  //背景色
  --background-color-1:#FAFAFA;
  //一级色
  --color-level-1: rgb(var(--base-color-level-1));
  //二级色
  --color-level-2: #555555;
  //三级色
  --color-level-3: rgb(var(--base-color-level-3));
  //主色
  --primary-color-1: rgb(var(--base-primary-color));
  //辅助色
  --secondary-color-1: rgb(var(--base-secondary-color-1));
  --secondary-color-2: rgb(var(--base-secondary-color-2));
  --secondary-color-3: #E8E8E8;
  --secondary-color-4: #FAFAFA;
  --secondary-color-5: #D7D7D7;
  --secondary-color-6: rgb(var(--base-secondary-color-6));
  --secondary-color-7: #FFA800;
  --secondary-color-8: rgb(var(--base-secondary-color-8));;
  --secondary-color-9: #262626;
  --secondary-color-10: #151515;
  --secondary-color-11: #EDEDED;
  --secondary-color-12: rgba(75, 75, 75,0.05);



  

  --secondary-color-13: #F0CB00;
  --secondary-color-14: #C6AC8F;
  --secondary-color-15: #847108;
  --secondary-color-16: #957E00;
  --secondary-color-17: #17181A;
  --secondary-color-18: #17181A;

  --secondary-color-19:rgba(75, 75, 75,0.5);
  --secondary-color-20:rgba(255, 255, 255, 0.05);
  --secondary-color-21: #F7F7F7;
  //渐变色
  --gradient-color-1: linear-gradient(270deg, #99BEFF 0%, #668DFF 100%);
  --gradient-color-2: linear-gradient(270deg, #454545 0%, #1F1F1F 100%);
  --gradient-color-3: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #5A85FF 100%);
  --gradient-color-4: linear-gradient(90deg, #668DFF 0%, rgba(102, 141, 255, 0.00) 100%);
  --gradient-color-5: linear-gradient(90deg, #FFF 0%, #FFF 10%, #E9E9E9 25%, #FFF 50%, #E9E9E9 75%, #FFF 90%, #FFF 100%);
  --gradient-color-6: linear-gradient(180deg, #484848 0%, #191919 100%);

  //自定义颜色
  //备注：vip详情 -> vip返水比例 -> 选中色
  --vip-detail-select-bg-color: rgba(102, 141, 255, 0.3);
   
}

[data-theme='night'] {
  --base-primary-color: 253, 214, 0;
  --base-secondary-color-1: 54, 57, 62;
  --base-color-level-3: 120, 123, 128;
  --base-secondary-color-2: 38, 41, 46;
  --base-color-level-1: 255, 255, 255;
  //背景色
  --background-color-1: #17181a;
  //一级色
  --color-level-1: rgb(var(--base-color-level-1));
  //二级色
  --color-level-2: #c4c8cc;
  //三级色
  --color-level-3: rgb(var(--base-color-level-3));
  //主色
  --primary-color-1: rgb(var(--base-primary-color));
  //辅助色
  --secondary-color-1: rgb(var(--base-secondary-color-1));
  --secondary-color-2: rgb(var(--base-secondary-color-2));
  --secondary-color-3: #1D1E20;
  --secondary-color-4: #17181A;
  --secondary-color-5: #131313;
  --secondary-color-6: #4F3B08;
  --secondary-color-7: rgb(var(--base-secondary-color-7));
  --secondary-color-8: #FFA800;
  --secondary-color-9: rgb(var(--base-secondary-color-9));
  --secondary-color-10: #ffffff80;
  --secondary-color-11: #FDD500;
  --secondary-color-12: #ffffff;
  --secondary-color-13: #F0CB00;
  --secondary-color-14: #C6AC8F;
  --secondary-color-15: #847108;
  --secondary-color-16: #957E00;
  --secondary-color-17: #17181A;
  --secondary-color-18: #17181A;

  --gradient-color-1: linear-gradient(270deg, #FFE791 0%, #FDD600 100%);
  --gradient-color-2: linear-gradient(180deg, #17181A 0%, #202124 100%);
  --gradient-color-3: linear-gradient(180deg, rgba(255, 227, 73, 0) 0%, rgba(255, 243, 180, 0.8) 100%);
  --gradient-color-4: linear-gradient(180deg, #FFDC1F 0%, #FFE348 49.6%, #FFD804 100%);
  --gradient-color-5: linear-gradient(180deg, #151517 0%, #131315 100%);
  --gradient-color-6: linear-gradient(180deg, rgba(38, 41, 46, 0) 0%, #3C414A 50%, rgba(38, 41, 46, 0) 100%);
  --gradient-color-7: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FDD600 100%);
  --gradient-color-8: linear-gradient(90deg, #73757B 0%, rgba(115, 117, 123, 0) 100%);
  --gradient-color-9: linear-gradient(90deg, #F6F5E1 0%, #FFEF99 57.5%, #EDD135 79.5%, #F6F5E1 100%);

  //自定义颜色
  //备注：vip详情 -> vip返水比例 -> 选中色
  --vip-detail-select-bg-color: rgba(102, 141, 255, 0.3);
}
