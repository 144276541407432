// :root {
//   --wm-marquee-bg-color: #555555;
//   --wm-marquee-trumpet-color: #c4c8cc;
// }
// [data-theme='night'] {
//   --wm-marquee-bg-color: #c4c8cc;
//   --wm-marquee-trumpet-color: #c4c8cc;
// }

.wm-marquee {
  padding: 5px 15px 5px 15px;
  position: relative;
  .adm-notice-bar {
    height: 20px;
    padding: 0;
    background-color: transparent;
    border: 0;
  }

  .adm-notice-bar-left {
    display: flex;
    align-items: center;
  }
}

.wm-marquee-item {
  margin-right: 100px;
  font-size: 10px;
  line-height: 22px;
  color: var(--secondary-color-2);
}

.wm-marquee-icon {
  width: 20px;
  height: 20px;
}
