:root {
  --areacode-header-color: #404040;
  --areacode-border-color: #f0f0f0;
}

[data-theme='night'] {
  --areacode-header-color: #ffffff;
  --areacode-border-color: #1d1e20;
}
.dialog_areacode {
  :global(.adm-dialog-content) {
    padding: 0;
    padding-top: 20px;
  }
}

.header h1 {
  font-size: 16px;
  font-weight: 600;
  color: var(--areacode-header-color);
  text-align: center;
}

.header {
  padding-bottom: 11px;
}

.content {
  width: 100%;
}

.body {
  height: 250px;
  overflow: hidden;
}

.scroll {
  height: 250px;
  overflow-y: auto;
}

.item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  border-top: 1px solid var(--areacode-border-color);
  font-size: 16px;
  color: var(--areacode-header-color);
}

.icon {
  margin-left: auto;
  width: 20px;
  height: 20px;
  color: #fddba2;
}

.area_code {
    width: 50px;
}
.area_name {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.loading {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}