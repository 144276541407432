

.body {
  background-color: var(--background-color-1);
  min-height: 100vh;
}

.error {
  padding-top: 10px;
}

.submit_button {
  margin-top: 20px;
}

.botton_tip {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-level-2);
  gap: 4px;
}

.botton_tip > span:last-child {
  color: var(--primary-color-1);
}
