.security_check {
  background-color: var(--secondary-color-8);
  padding-top: 0px !important;

  :global(.adm-dialog-action-row) {
    font-size: 16px;
  }
  :global(.adm-dialog-action-row > button:first-child) {
    color: var(--color-level-1);
    border-right: 1px solid #f0f0f0;
  }

  :global(.adm-dialog-action-row > button:last-child) {
    color: var(--primary-color-1);
  }
  :global(.adm-dialog-footer) {
    border: 1px solid #f0f0f0;
  }

  :global(.adm-dialog-content) {
    padding: 20px 16px 10px 16px;
  }
}

[data-theme='night'] {
  .security_check {
    :global(.adm-dialog-footer) {
      border: 1px solid transparent;
    }
    :global(.adm-dialog-action-row > button:first-child) {
      color: var(--color-level-1);
      border-right: 1px solid transparent;
    }
  }
}

.dialog_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
}

.dialog_content > span:first-child {
  font-size: 16px;
  font-weight: 600;
  color: var(--color-level-1);
}

.dialog_content > span:nth-child(2) {
  font-size: 12px;
  color: var(--color-level-2);
}

.dialog_content > span:nth-child(3) {
  font-size: 12px;
  color: var(--primary-color-1);
}

.dialog_content > button {
  background-color: #fddba2;
  padding: 10px 25px;
  border-radius: 4px;
  font-size: 12px;
  color: #8c5b19;
}
