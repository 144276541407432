// .lazyLoadingContent {
//     display: flex;
//     align-items: center;
//     width: 100%;
//     height: 100vh;
//     justify-content: center;
// }
// .skCubeGrid {
//     // width: 40px;
//     // height: 40px;
//     //margin: 100px auto; 
//     color: #757884;
//     margin-bottom: 200px;
//     text-wrap: nowrap;
//     font-size: 24px;
//     animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; 
// }
  
//   @-webkit-keyframes sk-cubeGridScaleDelay {
//     0%, 70%, 100% {
//               transform: scale3D(1, 1, 1);
//     } 35% {
//               transform: scale3D(0.5, 0.5, 1); 
//     }
//   }
  
//   @keyframes sk-cubeGridScaleDelay {
//     0%, 70%, 100% {
//               transform: scale3D(1, 1, 1);
//     } 35% {
//               transform: scale3D(.5, 0.5, 1);
//     } 
//   }

.loading_content {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
}
.loading {
    margin: auto;
}