.noData {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: var(--secondary-color-2);
    height: 100%;
}
.noData_img{
    width: 240px;
    height: 240px;
    margin-top: 40px;
}
.desc{
    font-size: 14px;
    color: var(--color-level-3);
    margin: 38px 0 20px;
}
