.icon {
    width: 20px;
    height: 20px;
    transform: scale(-1);
}

.content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.placeholder {
    font-size: 16px;
    color: var(--color-level-3);
}

.text {
    font-size: 16px;
    color: var(--color-level-1);
}