.content {
  background-color: var(--secondary-color-2);
  height: 80vh;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.top {
  position: relative;
  width: 100%;
  height: 60px;
}

.top span:first-child {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  font-size: 16px;
  color: var(--primary-color-1);
  margin-top: 1px;
}

.top span:last-child {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  color: var(--color-level-1);
}

.search {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 10px;
  padding-bottom: 3px;
}

.search input {
  border: 0;
  background-color: var(--secondary-color-1);
  padding: 10px 45px;
  border-radius: 46px;
  width: 100%;
  font-size: 14px;
  color: var(--color-level-2);
}
.search input::placeholder {
  color: var(--color-level-3);
}

.search_icon {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 30px;
  color: var(--color-level-2);
}
.body {
  padding: 0 17px;
}

.item {
  display: flex;
  align-items: center;
  padding: 16px 0px;
  gap: 4px;
  color: var(--color-level-1);
  border-bottom: 0.5px solid var(--secondary-color-5);
}

.item svg:last-child {
  margin-left: auto;
}

.item img {
  width: 24px;
  height: 24px;
}

.item span {
  font-size: 16px;
}

.scroll {
  height: 100%;
  overflow-y: scroll;
  margin-bottom: 69px;
  padding-bottom: 69px;
}

.loading {
  background-color: transparent !important;
}

.button_group {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 69px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-color-2);
  width: 100%;
  gap: 12px;
}

.button_group button {
  width: 167px;
  height: 44px;
  font-size: 16px;
  color: var(--color-level-2);
  border-radius: 8px;
}

.button_group button:last-child {
  background-color: var(--secondary-color-11);
  color:  var(--primary-color-1);
}