.wm-spin-loading .wm-spin-loading > div {
  position: relative;
  box-sizing: border-box;
}

.wm-spin-loading {
  display: block;
  font-size: 0;
  color: var(--secondary-color-16);
}

.wm-spin-loading > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.wm-spin-loading {
  width: 60px;
  height: 18px;
}

.wm-spin-loading > div {
  width: 10px;
  height: 10px;
  margin: 4px;
  border-radius: 100%;
  animation: ball-beat 0.7s -0.15s infinite linear;
}

.wm-spin-loading > div:nth-child(2n-1) {
  animation-delay: -0.5s;
}

@keyframes ball-beat {
  50% {
    opacity: 0.2;
    transform: scale(0.75);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
