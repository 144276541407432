.header_ui {
  position: relative;
  height: 44px;
  z-index: 11;
}

.header_view {
  height: 44px;
  line-height: 44px;
  background: var(--secondary-color-2);
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: var(--safe-html-max-width);
  top: 0;
  left: 50%;
  //width: 100%;
  z-index: 11;
  transform: translateX(-50%);
}

.left_view {
  flex: 1 1;
  display: flex;
  justify-content: center;
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
}

.center_view {
  max-width: calc(100% - 88px);
  white-space: nowrap;
  text-align: center;
  flex: 1 1;
  display: flex;
  justify-content: center;
  overflow: hidden;
  flex: auto;
  align-items: center;
  padding: 0 10px;
  margin-left: 15px;
}

.header_item_text {
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  color: var(--color-level-1);
  font-size: 16px;
  font-weight: 500;
  max-width: 100%;
  font-style: normal;
  line-height: 28px;
}

.right_view {
  flex: 1 1;
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  justify-content: flex-end;
  padding-right: 14px;
  font-size: 14px;
  color:  var(--color-level-2)
}

.btn_back {
  width: 24px;
  height: 24px;
  color: var(--color-level-1);
  margin-left: 7px;
}
