.dialog_confirm {
  padding-top: 0px !important;
  :global(.adm-dialog-content) {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-level-1);
    padding: 20px 24px 12px 24px;
  }
}

.dialog_content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hide_cancel {
  :global(.adm-button:first-child) {
    display: none;
  }
}

.hide_confirm {
  :global(.adm-button:last-child) {
    display: none;
  }
}
