.content {
  display: flex;
  align-items: center;
  justify-content: space-between;

  :global(.adm-image-uploader-upload-button-icon) {
    opacity: 0;
  }
  :global(.adm-image-uploader-cell-fail) {
    border: 0;
    box-sizing: content-box;
    overflow: unset;
  }
  :global(.adm-image-uploader-upload-button-wrap) {
    border: 0;
    box-sizing: content-box;
  }
  :global(.adm-image-uploader-cell-delete) {
    background-color: transparent;
  }
  :global(.adm-image-uploader-cell) {
    border: 0;
    box-sizing: content-box;
    overflow: unset;
  }
}

.image {
  width: 64px;
  height: 64px;
}
.text {
  font-size: 14px;
  color: var(--color-level-2);
}

.delete_icon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -5px;
  right: -5px;
}