
.adm-picker-view-mask-top,
.adm-picker-view-mask-bottom {
    background: var(--secondary-color-2) !important;
}

.adm-popup-body  {
    background-color: var(--secondary-color-2) !important;
    border-top-left-radius: 16px ;
    border-top-right-radius: 16px;
}

.adm-center-popup-body {
    background: var(--secondary-color-2) !important;
}

.adm-popup {
    contain: content;
    width: var(--safe-html-max-width);
    height: 100%;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
}

.adm-dialog-footer .adm-dialog-action-row {
    border-top: 0.5px solid var(--secondary-color-5) !important;
}

.adm-dialog-footer .adm-dialog-action-row > .adm-dialog-button {
    border-right: 1px solid var(--secondary-color-5) !important;
    font-size: 16px !important;
}

.adm-dialog-action-row button:last-child {
    color: var(--primary-color-1);
}

.adm-picker{
    font-size: 16px !important;
    color: var(--color-level-1);
    background: var(--secondary-color-2) !important;
}
.adm-picker-header-button{
    color: var(--primary-color-1);
}
.adm-picker-view-column-item-active{
    background-color:var(--secondary-color-1)
}