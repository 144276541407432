.verification_code {
    display: flex;
    align-items: center;
    gap: 20px;
}

.verification_code input {
    width: 32px;
    height: 36px;
    text-align: center;
    border: 0.5px solid var(--color-level-2);
    border-radius: 8px;

    font-size: 20px;
    color: var(--color-level-1);
    background-color: var(--secondary-color-2);
}

.line {
    border-bottom: 0.5px solid var(--color-level-3);
}
.line input{
    border: none;
}

.line input::placeholder {
    opacity: 0.4;
}

.line input:focus::placeholder{
    opacity: 0;
}