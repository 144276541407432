.games {
  padding: 3px 14px 0 14px;
  display: flex;
  gap: 0px;
  justify-content: center;
  position: relative;
  z-index: 0;
}

.tab_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.left {
  width: 52px;
  height: 10px;
  position: sticky;
}

.hide_downLoad {
  top: 114px;
}
.un_hide_downLoad {
  top: 158px;
}

.element {
  width: 291px;
  height: 130px;
  // border-radius: 8px;
  // box-shadow: 0px 3px 6px 0px #4b4b4b1a;
  position: relative;
  // background: var(--gradient-color-9);
}


.element_img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.element_img > img:first-child {
  width: 144px;
  position: absolute;
  top: 0;
  left: 3px;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  min-width: 291px;
}

.element_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.tab_img {
  width: 28px;
  height: 28px;
}

.tab_text {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 2px;
  color: var(--color-level-3);
}

.active .tab_text{
  color: var(--secondary-color-2);
}

.offical {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 35px;
  height: 35px;
}

.element_text {
  position: absolute;
  top: 22px;
  right: 19px;
}

.element_text > div:first-child {
  font-size: 20px;
  color: var(--color-level-1);
  font-weight: 600;
}

.element_text > div:nth-child(2) {
  color: var(--color-level-2);
  font-weight: 400;
  font-size: 10px;
}

.game_count {
  margin-top: 13px;
}
.game_count > span:first-child {
  font-size: 24px;
  color: var(--secondary-color-10);
  font-weight: 700;
}

.game_count > span:last-child {
  font-size: 15px;
  color: var(--secondary-color-10);
  margin-left: 4px;
}

.maintain {
  position: absolute;
  left: 5px;
  top: 0;
  width: 285px;
  height: 125px;
  background-color: #00000080;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: (9px);
  color: #fff;
  border-radius: 8px;
}

.maintain_icon {
  width: (20px);
  height: (20px);
}

.maintain_top {
  display: flex;
  align-items: center;
  gap: (6px);
  font-size: (12px);
}
.maintain_time {
  font-size: (10px);
}


.card_origin {
  position: absolute;
  top: 1px;
  right: 5px;
  width: 40px;
  height: 20px;
}

.element_bottom {
  margin-bottom: 130px;
}

